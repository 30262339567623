import {
  PACKAGE_LIST,
  PACKAGE_DATA,
  COMPANY_LIST,
  COMPANY_DATA,
  BRANCH_LIST,
  BRANCH_DATA,
  CATEGORY_LIST,
  CATEGORY_DATA,
  CATEGORY_SELECTBOX_LIST,
  SUCCESS_MESSAGE,
  ERROR_MESSAGE,
  IS_LOADING,
  LOGOUT,
  STAFF_LIST,
  STAFF_DATA,
  CUSTOMER_LIST,
  CUSTOMER_DATA,
  TAX_SELECTBOX_LIST,
  ITEM_LIST,
  ITEM_DATA,
  QUOTATION_LIST,
  QUOTATION_DATA,
  SALE_LIST,
  SALE_DATA,
  DELIVERY_CHALLLAN_DATA,
  DELIVERY_CHALLLAN_LIST,
} from '../actions/Admin'

const initialState = {
  error_message: '',
  success_message: '',
  is_loading: false,
  packageList: [],
  packageData: {},
  companyList: [],
  companyData: {},
  branchList: [],
  branchData: {},
  categoryList: [],
  categoryData: {},
  categorySelectBoxList: [],
  staffList: [],
  staffData: {},
  customerList: [],
  customerData: {},
  taxSelectBoxList: [],
  itemList: [],
  itemData: {},
  quotationList: [],
  quotationData: {},
  saleList: [],
  saleData: {},
  deliveryChallanList: [],
  deliveryChallanData: {},
}

const out = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT: {
      return {
        ...initialState,
      }
    }
    case SUCCESS_MESSAGE: {
      return {
        ...state,
        success_message: action.payload,
      }
    }
    case IS_LOADING: {
      return {
        ...state,
        is_loading: action.payload,
      }
    }
    case ERROR_MESSAGE: {
      return {
        ...state,
        error_message: action.payload,
      }
    }
    case PACKAGE_LIST: {
      return {
        ...state,
        packageList: action.payload,
      }
    }
    case PACKAGE_DATA: {
      return {
        ...state,
        packageData: action.payload,
      }
    }
    case COMPANY_LIST: {
      return {
        ...state,
        companyList: action.payload,
      }
    }
    case BRANCH_LIST: {
      return {
        ...state,
        branchList: action.payload,
      }
    }
    case BRANCH_DATA: {
      return {
        ...state,
        branchData: action.payload,
      }
    }
    case COMPANY_DATA: {
      return {
        ...state,
        companyData: action.payload,
      }
    }
    case CATEGORY_LIST: {
      return {
        ...state,
        categoryList: action.payload,
      }
    }
    case CATEGORY_DATA: {
      return {
        ...state,
        categoryData: action.payload,
      }
    }
    case CATEGORY_SELECTBOX_LIST: {
      return {
        ...state,
        categorySelectBoxList: action.payload,
      }
    }
    case STAFF_LIST: {
      return {
        ...state,
        staffList: action.payload,
      }
    }
    case STAFF_DATA: {
      return {
        ...state,
        staffData: action.payload,
      }
    }
    case CUSTOMER_LIST: {
      return {
        ...state,
        customerList: action.payload,
      }
    }
    case CUSTOMER_DATA: {
      return {
        ...state,
        customerData: action.payload,
      }
    }
    case TAX_SELECTBOX_LIST: {
      return {
        ...state,
        taxSelectBoxList: action.payload,
      }
    }
    case ITEM_LIST: {
      return {
        ...state,
        itemList: action.payload,
      }
    }
    case ITEM_DATA: {
      return {
        ...state,
        itemData: action.payload,
      }
    }
    case QUOTATION_LIST: {
      return {
        ...state,
        quotationList: action.payload,
      }
    }
    case QUOTATION_DATA: {
      return {
        ...state,
        quotationData: action.payload,
      }
    }
    case SALE_LIST: {
      return {
        ...state,
        saleList: action.payload,
      }
    }
    case SALE_DATA: {
      return {
        ...state,
        saleData: action.payload,
      }
    }
    case DELIVERY_CHALLLAN_LIST: {
      return {
        ...state,
        deliveryChallanList: action.payload,
      }
    }
    case DELIVERY_CHALLLAN_DATA: {
      return {
        ...state,
        deliveryChallanData: action.payload,
      }
    }
    default:
      return state
  }
}
export default out
