import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { legacy_createStore as createStore } from 'redux'
import { applyMiddleware } from 'redux'
import { combineReducers } from 'redux'

import AdminReducer from '../reducers/Admin'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootReducer = combineReducers({
  admin: AdminReducer,
})

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
)
export const persistedStore = persistStore(store)
