import { decode as base64_decode, encode as base64_encode } from 'base-64'
import moment from 'moment/moment.js'
import { Country, State, City } from 'country-state-city'

import CodeData from './json/CodeData'

import uploadImage from './assets/images/upload.png'
import companyAvatar from './assets/images/companyAvatar.png'
import userAvatar from './assets/images/userAvatar.png'

let Razorpaykey
let BaseUrl
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  Razorpaykey = 'rzp_test_j2kXzeZbYMltMy'
  BaseUrl = 'http://192.168.241.1:8080/'
} else {
  Razorpaykey = 'rzp_test_j2kXzeZbYMltMy'
  BaseUrl = 'https://www.api.integray.in/'
}

const CryptoJS = require('crypto-js')
const password = '0123456789karthi'
const key = CryptoJS.enc.Utf8.parse(password)
const iv = CryptoJS.lib.WordArray.random(16)
const encodedCrypto = (message) => {
  const encrypted = CryptoJS.AES.encrypt(message, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  const encryptedMessage = iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64)
  return encryptedMessage
}

const decodedCrypto = (encryptedMessage) => {
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Base64.parse(encryptedMessage) },
    key,
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 },
  )
  const decryptedMessage = decrypted.toString(CryptoJS.enc.Utf8)
  return decryptedMessage
}

const encoded = (str) => {
  return base64_encode(str)
}

const decoded = (str) => {
  return JSON.parse(base64_decode(str))
}

const getRequestToken = () => {
  var now = moment(new Date()).format('MMM DD YYYY hh')
  var sha512 = require('js-sha512')
  sha512 = sha512(now + 'vimkes')
  return sha512
}

const getSha = (password) => {
  var sha512 = require('js-sha512')
  sha512 = sha512(password)
  return sha512
}

const password_validation = (password, retype_password) => {
  if (password !== retype_password) {
    return 'Password Does Not Match'
  } else {
    return ''
  }
}

const countries = Country.getAllCountries()
const updatedCountries = countries.map((country) => ({
  key: country.isoCode,
  value: country.isoCode,
  label: country.name,
}))

const updatedStates = (countryCode) =>
  State.getStatesOfCountry(countryCode).map((state) => ({
    key: state.isoCode,
    value: state.isoCode,
    label: state.name,
  }))

const updatedCities = (countryCode, stateCode) =>
  City.getCitiesOfState(countryCode, stateCode).map((city) => ({
    key: city.latitude + '*' + city.longitude,
    value: city.latitude + '*' + city.longitude,
    label: city.name,
  }))

const isObject = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const stringToObject = (str) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return {}
  }
}

const getKey = (str) => {
  try {
    if (str.hasOwnProperty('key')) {
      return str.key
    } else {
      return ''
    }
  } catch (e) {
    return ''
  }
}

const getValue = (str) => {
  try {
    if (str.hasOwnProperty('value')) {
      return str.value
    } else {
      return ''
    }
  } catch (e) {
    return ''
  }
}

const getLabel = (str) => {
  try {
    if (str.hasOwnProperty('label')) {
      return str.label
    } else {
      return ''
    }
  } catch (e) {
    return ''
  }
}

const getObjectByKey = (list, key) => {
  let obj = {}
  if (key) {
    obj = list.find((o) => o.key === key)
  }
  return obj
}

const getObjectLabel = (str) => {
  try {
    let obj = JSON.parse(str);
    if (obj.hasOwnProperty('label')) {
      return obj.label;
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
};

const getObjectKey = (str) => {
  try {
    let obj = JSON.parse(str);
    if (obj.hasOwnProperty('key')) {
      return obj.key;
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
};

const getStateCode = (state_name) => {
  let obj = {}
  console.log(state_name)
  if (state_name) {
    obj = CodeData.GstStateCode.find((o) => o.state_name === state_name)
  }
  try {
    console.log(obj)
    if (obj.hasOwnProperty('state_code')) {
      return obj.state_code;
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
}

const escapeRegexCharacters = (str) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

const getDBDate = (date) => {
  if (date === "" || date === null) {
    date = "1000-01-01";
  } else {
    date = moment(date).format("YYYY-MM-DD");
  }
  return date
}

const stringToDate = (string_date) => {
  try {
    var parts = string_date.split("-");
    var mydate = new Date(parts[2] + "/" + parts[1] + "/" + parts[0]);
    if (
      parts[2] === "0999" ||
      parts[2] === "8999" ||
      parts[2] === "1000" ||
      parts[2] === "9000"
    ) {
      mydate = "";
    }
    if (mydate.toString() === "Invalid Date") {
      return "";
    }
    return mydate;
  } catch (err) {
    return string_date;
  }
};

const periodTypeList = () => {
  var list = [
    { key: "Y", value: "Y", label: "Year's", checked: false },
    { key: "M", value: "M", label: "Month's", checked: false },
    { key: "D", value: "D", label: "Day's", checked: false },
  ];
  return list;
};

const addIcon = 'ic:twotone-post-add'
const editIcon = 'iconoir:page-edit'
const deleteIcon = 'material-symbols:folder-delete-outline'
const searchIcon = 'ant-design:file-search-outlined'
const submitIcon = 'iconoir:submit-document'
const prewIcon = 'material-symbols:preview-outline'
const filterIcon = 'ep:filter'

export default {
  BaseUrl,
  Razorpaykey,
  encodedCrypto,
  decodedCrypto,
  encoded,
  decoded,
  getRequestToken,
  getSha,
  password_validation,
  updatedCountries,
  updatedStates,
  updatedCities,
  isObject,
  stringToObject,
  addIcon,
  editIcon,
  deleteIcon,
  searchIcon,
  submitIcon,
  prewIcon,
  uploadImage,
  companyAvatar,
  userAvatar,
  getKey,
  getValue,
  getLabel,
  getObjectByKey,
  escapeRegexCharacters,
  getDBDate,
  getObjectLabel,
  getObjectKey,
  stringToDate,
  getStateCode,
  periodTypeList,
  filterIcon,
}
