import http from '../../http_common'
import Config from '../../Config'

export const PACKAGE_LIST = 'PACKAGE_LIST'
export const PACKAGE_DATA = 'PACKAGE_DATA'

export const COMPANY_LIST = 'COMPANY_LIST'
export const COMPANY_DATA = 'COMPANY_DATA'
export const BRANCH_LIST = 'BRANCH_LIST'
export const BRANCH_DATA = 'BRANCH_DATA'

export const TAX_SELECTBOX_LIST = 'TAX_SELECTBOX_LIST'
export const CATEGORY_SELECTBOX_LIST = 'CATEGORY_SELECTBOX_LIST'
export const CATEGORY_LIST = 'CATEGORY_LIST'
export const CATEGORY_DATA = 'CATEGORY_DATA'

export const STAFF_LIST = 'STAFF_LIST'
export const STAFF_DATA = 'STAFF_DATA'
export const CUSTOMER_LIST = 'CUSTOMER_LIST'
export const CUSTOMER_DATA = 'CUSTOMER_DATA'
export const ITEM_LIST = 'ITEM_LIST'
export const ITEM_DATA = 'ITEM_DATA'
export const QUOTATION_LIST = 'QUOTATION_LIST'
export const QUOTATION_DATA = 'QUOTATION_DATA'
export const SALE_LIST = 'SALE_LIST'
export const SALE_DATA = 'SALE_DATA'
export const DELIVERY_CHALLLAN_LIST = 'DELIVERY_CHALLLAN_LIST'
export const DELIVERY_CHALLLAN_DATA = 'DELIVERY_CHALLLAN_DATA'

export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE'
export const ERROR_MESSAGE = 'ERROR_MESSAGE'
export const IS_LOADING = 'IS_LOADING'
export const LOGOUT = 'LOGOUT'

export const empty_message = () => {
  return (dispatch) => {
    dispatch({ type: 'SUCCESS_MESSAGE', payload: '' })
    dispatch({ type: 'ERROR_MESSAGE', payload: '' })
    return true
  }
}

export const AddPackage = (formData) => {
  return async (dispatch) => {
    http
      .post('/AddPackage', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddPackage' })
        } else {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddPackage' })
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'PACKAGE_SUCCESS_MESSAGE', payload: 'AddEmployers' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetPackageList = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetPackageList', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetPackageList' })
        dispatch({ type: 'PACKAGE_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetPackageList' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetPackageData = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetPackageData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetPackageData' })
        dispatch({ type: 'PACKAGE_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetPackageData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const DeletePackageData = (formData) => {
  return async (dispatch) => {
    http
      .post('/DeletePackageData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeletePackageData' })
        } else {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeletePackageData' })
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeletePackageData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: 'LOGOUT', payload: '' })
    return true
  }
}

export const SignUpAction = (formData) => {
  return (dispatch) => {
    http
      .post('/SignUpAction', formData, {
        headers: { Authorization: Config.getRequestToken() },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'SignUpAction' })
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'SignUpAction' })
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'SignUpAction' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const SignInAction = (formData) => {
  return (dispatch) => {
    http
      .post('/SignInAction', formData, {
        headers: { Authorization: Config.getRequestToken() },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'SignInAction' })
        let res = Config.decoded(responseJson.data)
        console.log(res)
        if (res?.message === 'SUCCESS') {
          localStorage.setItem('company_id_1', res?.company_id)
          localStorage.setItem('company_name_1', res?.company_name)
          localStorage.setItem('company_logo_1', res?.company_logo || Config.companyAvatar)
          localStorage.setItem('branch_id_1', res?.branch_id)
          localStorage.setItem('branch_name_1', res?.branch_name)
          localStorage.setItem('branch_location_1', res?.branch_location)
          localStorage.setItem('branch_logo_1', res?.branch_logo || Config.companyAvatar)
          localStorage.setItem('registration_id', res?.lid)
          localStorage.setItem('lid', res?.lid)
          localStorage.setItem('master_id', res?.master_id)
          localStorage.setItem('entity_id', res?.entity_id)
          localStorage.setItem('login_history_id', res?.login_history_id)
          localStorage.setItem('last_sig', res?.last_sig)
          localStorage.setItem('Authorization', res?.token.toString())
          localStorage.setItem('type_of_entity', res?.type_of_entity)
          localStorage.setItem('user_type', res?.user_type)
          localStorage.setItem('first_name', res?.first_name)
          localStorage.setItem('last_name', res?.last_name)
          localStorage.setItem('profile_image', res?.profile_image || Config.userAvatar)
          localStorage.setItem('role', res?.role)
          localStorage.setItem('resume_text', res?.resume_text)
          localStorage.setItem('resume_file', res?.resume_file)
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
        return true
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'SignInAction' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const LoginWithSocialID = (formData) => {
  return (dispatch) => {
    http
      .post('/LoginWithSocialID', formData, {
        headers: { Authorization: Config.getRequestToken() },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'LoginWithSocialID' })
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          localStorage.setItem('registration_id', res?.lid)
          localStorage.setItem('lid', res?.lid)
          localStorage.setItem('master_id', res?.master_id)
          localStorage.setItem('entity_id', res?.entity_id)
          localStorage.setItem('login_history_id', res?.login_history_id)
          localStorage.setItem('last_sig', res?.last_sig)
          localStorage.setItem('Authorization', res?.token.toString())
          localStorage.setItem('type_of_entity', res?.type_of_entity)
          localStorage.setItem('user_type', res?.user_type)
          localStorage.setItem('first_name', res?.first_name)
          localStorage.setItem('last_name', res?.last_name)
          localStorage.setItem('profile_image', res?.profile_image)
          if (res?.profile_image === '') {
            localStorage.setItem('profile_image', Config.userAvatar)
          }
          localStorage.setItem('role', res?.role)
          localStorage.setItem('resume_text', res?.resume_text)
          localStorage.setItem('resume_file', res?.resume_file)
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
        return true
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'LoginWithSocialID' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const RefreshToken = (formData) => {
  return (dispatch) => {
    http
      .post('/RefreshToken', formData, {
        headers: { Authorization: Config.getRequestToken() },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'RefreshToken' })
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          localStorage.setItem('Authorization', res?.token)
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'RefreshToken' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const LogOutAction = (formData) => {
  return (dispatch) => {
    http
      .post('/LogOutAction', formData, {
        headers: { Authorization: Config.getRequestToken() },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'LogOutAction' })
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'LogOutAction' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const ChangePasswordAction = (formData) => {
  return (dispatch) => {
    http
      .post('/ChangePasswordAction', formData, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'ChangePasswordAction' })
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'ChangePasswordAction' })
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'ChangePasswordAction' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetResetPasswordLinkByMail = (formData) => {
  return (dispatch) => {
    http
      .post('/GetResetPasswordLinkByMail', formData, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetResetPasswordLinkByMail' })
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetResetPasswordLinkByMail' })
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetResetPasswordLinkByMail' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const MailVerification = (formData) => {
  return (dispatch) => {
    http
      .post('/MailVerification', formData, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'MailVerification' })
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'MailVerification' })
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'MailVerification' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const AddCompany = (formData) => {
  return (dispatch) => {
    http
      .post('/AddCompany', formData, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddCompany' })
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddCompany' })
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddCompany' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const AddBranch = (formData) => {
  return (dispatch) => {
    http
      .post('/AddBranch', formData, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddBranch' })
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddBranch' })
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddBranch' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const DeleteBranchData = (formData) => {
  return (dispatch) => {
    http
      .post('/DeleteBranchData', formData, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteBranchData' })
        let res = Config.decoded(responseJson.data)
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteBranchData' })
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteBranchData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetCompanyData = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetCompanyData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCompanyData' })
        dispatch({ type: 'COMPANY_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCompanyData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetBranchData = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetBranchData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetBranchData' })
        dispatch({ type: 'BRANCH_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetBranchData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetBranchListByPagination = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetBranchListByPagination', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetBranchListByPagination' })
        dispatch({ type: 'BRANCH_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetBranchListByPagination' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const AddCategory = (formData) => {
  return async (dispatch) => {
    http
      .post('/AddCategory', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddCategory' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'PACKAGE_SUCCESS_MESSAGE', payload: 'AddCategory' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetCategoryList = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetCategoryList', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCategoryList' })
        dispatch({ type: 'CATEGORY_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCategoryList' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetCategoryData = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetCategoryData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCategoryData' })
        dispatch({ type: 'CATEGORY_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCategoryData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const DeleteCategoryData = (formData) => {
  return async (dispatch) => {
    http
      .post('/DeleteCategoryData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteCategoryData' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteCategoryData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetCategorySelectBoxList = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetCategorySelectBoxList', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCategorySelectBoxList' })
        dispatch({ type: 'CATEGORY_SELECTBOX_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCategorySelectBoxList' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const AddCustomer = (formData) => {
  return async (dispatch) => {
    http
      .post('/AddCustomer', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddCustomer' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'PACKAGE_SUCCESS_MESSAGE', payload: 'AddCustomer' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetCustomerListByPagination = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetCustomerListByPagination', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCustomerListByPagination' })
        dispatch({ type: 'CUSTOMER_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCustomerListByPagination' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetCustomerData = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetCustomerData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCustomerData' })
        dispatch({ type: 'CUSTOMER_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetCustomerData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const DeleteCustomerData = (formData) => {
  return async (dispatch) => {
    http
      .post('/DeleteCustomerData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteCustomerData' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteCustomerData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const AddStaff = (formData) => {
  return async (dispatch) => {
    http
      .post('/AddStaff', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddStaff' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'PACKAGE_SUCCESS_MESSAGE', payload: 'AddStaff' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetStaffListByPagination = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetStaffListByPagination', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetStaffListByPagination' })
        dispatch({ type: 'STAFF_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetStaffListByPagination' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetStaffData = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetStaffData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetStaffData' })
        dispatch({ type: 'STAFF_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetStaffData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const DeleteStaffData = (formData) => {
  return async (dispatch) => {
    http
      .post('/DeleteStaffData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteStaffData' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteStaffData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetTaxSettingSelectBoxList = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetTaxSettingSelectBoxList', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetTaxSettingSelectBoxList' })
        dispatch({ type: 'TAX_SELECTBOX_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetTaxSettingSelectBoxList' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const AddItem = (formData) => {
  return async (dispatch) => {
    http
      .post('/AddItem', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddItem' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'PACKAGE_SUCCESS_MESSAGE', payload: 'AddItem' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetItemListByPagination = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetItemListByPagination', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetItemListByPagination' })
        dispatch({ type: 'ITEM_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetItemListByPagination' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetItemData = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetItemData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetItemData' })
        dispatch({ type: 'ITEM_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetItemData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const DeleteItemData = (formData) => {
  return async (dispatch) => {
    http
      .post('/DeleteItemData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteItemData' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteItemData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const AddQuotation = (formData) => {
  return async (dispatch) => {
    http
      .post('/AddQuotation', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddQuotation' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'PACKAGE_SUCCESS_MESSAGE', payload: 'AddQuotation' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetQuotationList = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetQuotationList', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetQuotationList' })
        dispatch({ type: 'QUOTATION_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetQuotationList' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetQuotationData = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetQuotationData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetQuotationData' })
        dispatch({ type: 'QUOTATION_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetQuotationData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetQuotationPreview = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetQuotationPreview', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetQuotationPreview' })
        dispatch({ type: 'QUOTATION_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetQuotationPreview' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const DeleteQuotationData = (formData) => {
  return async (dispatch) => {
    http
      .post('/DeleteQuotationData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteQuotationData' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteQuotationData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetId = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetId', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetId' })
        dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetId' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const AddSale = (formData) => {
  return async (dispatch) => {
    http
      .post('/AddSale', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddSale' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'PACKAGE_SUCCESS_MESSAGE', payload: 'AddSale' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetSaleList = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetSaleList', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetSaleList' })
        dispatch({ type: 'SALE_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetSaleList' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetSaleData = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetSaleData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetSaleData' })
        dispatch({ type: 'SALE_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetSaleData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetSalePreview = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetSalePreview', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetSalePreview' })
        dispatch({ type: 'SALE_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetSalePreview' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const DeleteSaleData = (formData) => {
  return async (dispatch) => {
    http
      .post('/DeleteSaleData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteSaleData' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteSaleData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const AddDeliveryChallan = (formData) => {
  return async (dispatch) => {
    http
      .post('/AddDeliveryChallan', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'AddDeliveryChallan' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'PACKAGE_SUCCESS_MESSAGE', payload: 'AddDeliveryChallan' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetDeliveryChallanList = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetDeliveryChallanList', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetDeliveryChallanList' })
        dispatch({ type: 'DELIVERY_CHALLLAN_LIST', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetDeliveryChallanList' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetDeliveryChallanData = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetDeliveryChallanData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetDeliveryChallanData' })
        dispatch({ type: 'DELIVERY_CHALLLAN_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetDeliveryChallanData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const GetDeliveryChallanPreview = (formData) => {
  return async (dispatch) => {
    http
      .post('/GetDeliveryChallanPreview', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetDeliveryChallanPreview' })
        dispatch({ type: 'DELIVERY_CHALLLAN_DATA', payload: Config.decoded(responseJson.data) })
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'GetDeliveryChallanPreview' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}

export const DeleteDeliveryChallanData = (formData) => {
  return async (dispatch) => {
    http
      .post('/DeleteDeliveryChallanData', formData, {
        headers: {
          Authorization: localStorage.getItem('Authorization') || Config.getRequestToken(),
          entity_id: localStorage.getItem('entity_id'),
          branchCode: localStorage.getItem('branchCode'),
        },
      })
      .then((responseJson) => {
        if (responseJson.data === '') {
          localStorage.setItem('Authorization', '')
        }
        let res = Config.decoded(responseJson.data)
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteDeliveryChallanData' })
        if (res?.message === 'SUCCESS') {
          dispatch({ type: 'ERROR_MESSAGE', payload: '' })
        } else {
          dispatch({ type: 'ERROR_MESSAGE', payload: res?.message })
        }
      })
      .catch((error) => {
        dispatch({ type: 'SUCCESS_MESSAGE', payload: 'DeleteDeliveryChallanData' })
        dispatch({ type: 'ERROR_MESSAGE', payload: error })
      })
    return true
  }
}